<template>
  <section id="WhereWeAre">
    <div class="whereweare-mobile">
      <WhereWeAreMobile />
    </div>
    <div class="left">
      <img src="@/assets/images/VenueOverview/WhereWeAre/bg-casino-map-all.jpg" class="mapBg" />
      <div class="left-title">
        <span>Select Starting Location</span>
        <div class="horizontal-ruler"></div>
      </div>
      <WhereWeArePins class="left-image" :whereWeAreVenueArr="whereWeAreVenueArr" />
    </div>
    <div class="right"></div>
    <!-- <img class="right" src="@/assets/images/VenueOverview/WhereWeAre/map-allegiant-stadium.png" alt="allegiant stadium" /> -->
  </section>
</template>

<script>
import WhereWeArePins from "@/components/sections/Venue/WhereWeAre/Paths/WhereWeArePins";
import WhereWeAreMobile from "@/components/sections/Venue/WhereWeAre/WhereWeAreMobile";

export default {
  name: "WhereWeAre",
  components: {
    WhereWeArePins,
    WhereWeAreMobile
  },
  data() {
    return {
      whereWeAreVenueArr: []
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;
    pages.forEach(page => {
      if (page.parent === "10") {
        this.whereWeAreVenueArr.push(page.id);
		
      }
    });

  }
};
</script>

<style lang="scss" scoped>
#WhereWeAre {
  position: relative;
  background: black;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media (max-width: $lg) {
    overflow: visible;
    height: auto;
  }
  .left-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .left {
    // background-image: url("~@/assets/images/VenueOverview/WhereWeAre/bg-casino-map-all.jpg");
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-position-x: left;
    // background-position-y: bottom;
    // background-size: cover;
    height: 100vh;
    @media (max-width: $lg) {
      display: none;
    }
    .mapBg {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &-title {
      position: absolute;
      top: 161px;
      left: 317px;
      font-family: "ArtegraSans-Bold";
      font-size: 35px;
      color: #ffffff;
      letter-spacing: 2.19px;
      line-height: 32px;
      text-transform: uppercase;
      width: 233px;
      .horizontal-ruler {
        width: 196px;
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
        margin-top: 20px;
      }
    }
    &-image {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .link {
      position: absolute;
      cursor: pointer;
      width: 20px;
      height: 27px;
    }
    .stratosphere {
      bottom: 715px;
      left: 758px;
      width: 35px;
      height: 40px;
    }
    .conventioncenter {
      bottom: 618px;
      left: 810px;
      width: 35px;
      height: 40px;
    }
    .encorewynn {
      bottom: 560px;
      left: 673px;
    }
    .venetian {
      bottom: 495px;
      left: 636px;
    }
    .caesarspalace {
      bottom: 433px;
      left: 588px;
    }
    .bellagio {
      bottom: 390px;
      left: 566px;
    }
    .aria {
      bottom: 332px;
      left: 575px;
    }
    .parkmgm {
      bottom: 298px;
      left: 592px;
    }
    .newyork {
      bottom: 270px;
      left: 587px;
    }
    .mgm {
      bottom: 275px;
      left: 629px;
    }
    .luxor {
      bottom: 190px;
      left: 573px;
    }
    .mandalay {
      bottom: 140px;
      left: 580px;
    }
    .spacasino {
      bottom: 110px;
      left: 718px;
      height: 40px;
    }
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30%;
    min-width: 420px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    background-image: url("~@/assets/images/VenueOverview/WhereWeAre/map-allegiant-stadium.png");
    z-index: 2;
    animation: animateRight 1s ease-in-out 0s 1;
    animation-fill-mode: forwards;
    @media (max-width: $lg) {
      display: none;
    }
  }
  @keyframes animateRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .whereweare-mobile {
    display: none;
    @media (max-width: $lg) {
      display: block;
    }
  }
}
</style>
